.c-footer{
    min-height: 24rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem;

    .c-footer_top{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 28px;

        .c-footer_nav{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex-grow: 1.5;
            border-right: 1px solid $light-blue;

            .c-footer_nav_img{
                width: 20rem;
                padding-bottom: 2rem;
            }

            details{
                height: 5rem;
                width: 20rem;
                margin-right: inherit;
                margin-left: inherit;

                .c-header_uppernav_icon{
                    margin-right: 1rem;
                }

                ul{
                    top: -437%;
                    left: calc(100% + .5rem);

                    @media (max-width: 610px){
                        top: 115%;
                        left: 0;
                    }
                }
            }
        }

        @media (max-width: 999px){
            .c-footer_nav, .c-footer_info{
                padding: 0 3%;
            }
        }

        @media (max-width: 682px){
            .c-footer_info{
                padding: 3rem 3% 1rem;
            }

            .c-footer_nav{
                border-right: inherit;
                border-bottom: 1px solid $light-blue;
                margin: 0 auto;
                padding-bottom: 3rem;

                a{
                    margin: 0 auto;
                }
            }
        }

        @media (max-width: 548px){
            .c-footer_info, .c-footer_reseaux{
                text-align: center;
            }

            .c-footer_reseaux{
                .c-footer_socials{
                    justify-content: center;
                }
            }
        }
    }

    .c-footer_bottom{
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: $dark-blue;
        position: absolute;
        bottom: 0;
        left: 0;
        
        p, a{
            padding: 1rem 8rem;
            color: $light-blue;
            font-family: 'Libre';

            @media (max-width: 745px){
                padding: 1rem 2rem;
            }
        }
    }

    @media (max-width: 420px){
        padding: 6rem 2rem;

        .c-footer_bottom{
            a{
                padding: 1rem;
            }
        }
    }
}
.c-footer_info{
    align-self: flex-start;
    min-width: 30%;
    flex-grow: 1;
    padding-left: 15%;

    .c-footer_info_title{
        font-size: $font-larger;
        color: $light-blue;
        font-weight: bold;
        padding-bottom: 1rem;
    }

    .c-footer_info_text{
        color: $light-blue !important;
    }
}

.c-footer_reseaux{
    align-self: flex-start;
    color: $light-blue;
    font-weight: bold;
    text-transform: uppercase;
    flex-grow: 1;

    p{
        padding-bottom: 1rem;
        font-size: 20px;
    }

    .c-footer_socials{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .c-footer_socials_img{
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background-color: $medium-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;

            svg{
                height: 1.5rem;

                path{
                    fill: white;
                }
            }
        }
    }

    @media (max-width: 822px){
        padding: 3rem 1rem 0;
    }
}
