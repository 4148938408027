.c-table {
  .c-table_tr {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .c-table_head {
    margin-bottom: rem($unit-tiny);
  }
  .c-table_td, .c-table_th {
    flex: 1;
  }
  .c-table_th {
    font-weight: $medium;
  }
}
