.b-etapes{
    .b-etapes_item_head{
        display: flex;
        padding-bottom: 5rem;

        span{
            font-size: 155px;
            color: $sky-blue;
            padding-right: 3rem;
            font-weight: bold;
        }

        .b-etapes_item_title{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin-bottom: inherit;

            .b-etapes_item_text{
                padding-top: 2.5rem;
            }
        }

        @media (max-width: 550px){
            flex-direction: column;
            align-items: center;
        }
    }
}
