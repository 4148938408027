.b-tiroirs{
    .b-tiroirs-accordion{
        width: 8%;
        min-width: 50px;
        height: 100%;
        background-color: $grey-light;
        border-radius: 0 20px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
