.b-organigramme{
    .b-organigramme_container{
        padding: 2rem 0;
        overflow: hidden;
        position: relative;

        .b-organigramme_head{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 2rem;

            h1{
                text-transform: uppercase;
                flex-grow: 1;
                padding-bottom: 0.5rem;
                margin-bottom: inherit;
                display: flex;
                flex-direction: column;
                position: relative;

                &::after{
                    content: '';
                    background-color: $primary;
                    height: 3px;
                    width: calc(100% - 10rem);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            .b-organigramme_before, .b-organigramme_after{
                padding: 0.5rem;
                border-bottom: 2px solid $second;
                cursor: pointer;
                position: relative;
                overflow: hidden;

                svg{
                    position: relative;

                    path{
                        transition: 0.3s ease-out;
                    }
                }
                
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    background-color: $second;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 10rem 10rem 0 0;
                    transform: translate(0%, 150%);
                    transform-origin: center;
                    transition: 0.3s ease-out;
                }
                
                &:hover {
                    &:before {
                        height: 100%;
                        width: 100%;
                        border-radius: inherit;
                        transform: translateY(0);
                    }
                    svg{
                        z-index: 2;

                        path{
                            fill: white;
                        }
                    }
                }
            }

            .b-organigramme_before{
                margin-right: 1rem;
                margin-left: 1rem;

                svg{
                    transform: rotate(180deg);
                }
            }
        }

        .b-organigramme_content{
            display: flex;
            transition: 0.3s ease;
            position: initial !important;

            .b-organigramme_card{
                display: flex;
                padding-right: 3rem;
                transition: 0.5s ease;

                .b-organigramme_img{
                    width: 100px;
                    display: contents;
                }

                .b-organigramme_info{
                    padding-left: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    p:first-of-type{
                        display: flex;
                        font-size: 20px;
                        color: $primary;
                        font-weight: bolder;
                        text-transform: uppercase;
                        font-family: 'Kanit';

                        span{
                            padding-left: 0.3rem;
                        }
                    }

                    p:last-of-type{
                        color: $second;
                        font-weight: 400;
                    }
                }

                @media (max-width: 699px){
                    width: 50% !important;
                }
            }

            .owl-nav{
                display: flex;
                align-items: center;
                position: absolute;
                top: 39px;
                right: 8rem;

                .owl-next, .owl-prev{
                    width: 3rem;
                    height: 3rem;
                    border-bottom: 3px solid $second !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    cursor: pointer;
                    overflow: hidden;
                    border-radius: inherit !important;
            
                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        width: 3rem;
                        height: 3rem;
                        background-color: $second;
                        transition: all .3s ease-out;
                        border-radius: 10rem 10rem 0 0;
                        transform: translateY(100%);
                    }
                    svg{
                        position: relative;
                        z-index: 2;
                        transition: 0.3s ease-out;
                    }
                    &:hover {
                        background: inherit !important;
                        &:before {
                            height: 100%;
                            border-radius: inherit;
                            transform: translateY(0);
                        }
                        svg{
                            z-index: 1;
                            path{
                                fill: white;
                            }
                        }
                    }
                }
            
                .owl-prev{
                    svg{
                        transform: rotate(180deg);
                    }
                }

                .owl-next{
                    margin: 0 5rem;
                }

                @media (max-width: 1599px){
                    top: 36px;
                }

                @media (max-width: 1199px){
                    top: 34px;
                }

                @media (max-width: 999px){
                    top: 20px;
                }
            }
        }
    }
}
