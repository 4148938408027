.c-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem($unit);
  svg, img {
    width: rem(300px);
  }
  transition: transform 1.5s $easing 2s, visibility 0s 4s;
  .c-loader_wrap {
    padding: rem($unit);
  }
  .c-loader_logo {
    transform: translateY(#{rem($unit)});
    opacity: 0;
    transition: transform 1s $easing, opacity 1s $easing, visibility 0s;
    visibility: hidden;
  }
}

.is-animated.is-ready , .is-transitioned, .is-transitioning {
  .c-loader {
    transform: translateY(-100%);
    visibility: hidden;
  }
  .c-loader_logo {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
