.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second!important;
}
.u-c-grey {
  color: $grey !important;
}
.u-c-greyLight{
  color: #A0A0A0 !important;
}
.u-c-white {
  color: $white!important;
}
// .u-c-second {
//   color: $second;
// }
// .u-bg-primary {
//   background-color: $primary;
// }
// .u-bg-second {
//   background-color: $second;
// }
.u-bg-sky-blue {
  background-color: $sky-blue;
}
.u-bg-grey-day {
  background-color: $grey-day;
}