.b-onglet{
    .o-container{
        display: flex;
        flex-direction: column;

        &::after{
            content: '';
            width: 100%;
            height: 3px;
            background-color: $primary;
            margin-top: 2rem;
        }
    }

    .b-onglet_nav{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        border-bottom: 3px solid $primary;
        width: 100%;
        padding-top: 2rem;

        .b-onglet_nav_title{
            padding-bottom: 0.5rem;
            margin-right: 2rem;
            transform: translateY(2px);
            color: $primary;
            margin-bottom: inherit;
            cursor: pointer;

            h1{
                padding-bottom: 0.5rem;
            }
        }

        .clicked{
            border-bottom: 8px solid $second;
            color: $second;
            transform: translateY(calc(0.5rem + 1px));
        }
    }

    .b-onglet_container{
        display: flex;
        width: max-content;
        overflow: hidden;

        .b-onglet_text{
            padding-top: 4rem;
            padding-right: 22vw;
            width: 100vw;
            transition: 0.5s ease;

            p{
                columns: 2;
                column-gap: 3rem;

                span{
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: inherit;
                }
            }

            @media (max-width: 680px){
                padding-right: 5rem;

                p{
                    columns: inherit;
                }
            }

            @media (min-width: 1400px){
                padding-right: 20vw;
            }

            @media (min-width: 1800px){
                padding-right: 30vw;
            }
        }
    }
}