// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #1A1610;
$primary : #193153;
$second : #DC7344;
$light-blue: #7694BE;
$sky-blue: #DCE2EA;
$medium-blue: #29456C;
$dark-blue: #122A4D;
$green: #77C483;
$red: #D1603E;
$grey: #707070;
$grey2: #A0A0A0;
$grey-light: #F7F7F7;
$black-overlay-light: rgba(0, 0, 0, 0.5);
$grey-day: #F6F7FA;


// Specific
// =============================================================================
// Link
$link-color:       $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $primary;
$selection-background-color: $light-blue;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
