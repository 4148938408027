// ==========================================================================
// Base / Fonts
// ==========================================================================

@include font-face(
    $font-kanit,
    "fonts/Kanit-Medium"
) {
    font-size: normal;
    font-weight: 500;
}

@include font-face(
    $font-libre,
    "fonts/LibreFranklin-Regular",
    ("woff", "woff2", "eot")
) {
    font-size: normal;
    font-weight: 500;
}
