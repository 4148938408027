.c-transition{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  visibility: hidden;
  transition: visibility 0s .9s;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    opacity: 0;
    transform: translateY(2rem);
    transition: 0.3s $easing;
  }
}
.c-transition:before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $primary;
  transform: scaleY(0);
  transition: transform 0.8s $easing;
  transform-origin: center top;
}
.is-transitioned:not(.is-ready) .c-transition,
.is-transitioning .c-transition{
  visibility: visible;
  transition-delay: 0s;
  img {
    opacity: 1;
    transform: translateY(0);
  }
}
.is-transitioned:not(.is-ready) .c-transition:before,
.is-transitioning .c-transition:before{
  transform: scaleY(1);
  transform-origin: center bottom;
  transition-delay: 0s;
}
