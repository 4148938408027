.b-cta{
    background-color: $primary;
    padding: 3rem 0;
    display: flex;
    align-items: center;
    min-height: 560px;
    position: relative;

    .b-cta_imgDeco{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .o-layout_item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-heading{
        color: white;
        font-size: $font-size-h3;
        text-transform: uppercase;
        padding-bottom: 1.5rem;

        p{
            text-align: center;
        }
    }
}
