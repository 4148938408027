.c-breadcrumb {
    display: flex;
    align-items: center;
    gap: $unit-tinier;
    border-bottom: 1px solid $white;
    margin-bottom: $unit-small;
    padding-bottom: $unit-tinier;
    a, span {
        color: $white;
    }
    a {
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
    }
    .c-breadcrumb_separator {

    }
}