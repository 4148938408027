.b-timeline{
    position: relative;

    .-h3{
        margin-bottom: 2rem;
    }

    .b-timeline_nav{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .b-timeline_date_container{
            padding: 1rem 1rem 0 0;

            .b-timeline_date{
                color: $second !important;
                padding: 0.5rem 1rem;
                border-bottom: 2px solid $second;
                letter-spacing: inherit;
                font-weight: bold;
                cursor: pointer;
            } 
        }
        
        .b-timeline_active{
            flex-grow: 1;
            display: flex;
            align-items: center;
            position: relative;

            .b-timeline_date{
                background-color: $second;
                color: $white !important;
                display: flex;
                position: relative;
                width: fit-content;

                &::after{
                    content: '';
                    height: 2px;
                    width: 100px;
                    position: absolute;
                    bottom: 0;
                    right: 1rem;
                }
            }

            &::after{
                content: '';
                height: 2px;
                width: calc(100% - 90px);
                background-color: $primary;
                position: absolute;
                right: 1rem;
                bottom: 0;
            }
        }
    }

    .b-timeline_content{
        display: flex;
        width: max-content;

        .b-timeline_article{
            padding-top: 3rem;
            padding-right: 22vw;
            width: 100vw;
            transition: 0.5s ease;

            h1{
                padding-bottom: 1.5rem;
            }

            // img{
            //     padding-bottom: 1.5rem;
            //     height: 60%;
            //     width: 100%;
            //     object-fit: cover;
            //     object-position: center;
            // }

            @media (max-width: 698px){
                padding-right: 3rem;
            }

            @media (max-width: 473px){
                height: 60rem;
            }

            @media (min-width: 1400px){
                padding-right: 20vw;
            }

            @media (min-width: 1800px){
                padding-right: 30vw;
            }
        }
    }
}