.t-login {
    min-height: calc(100vh - 8rem);
    .o-container {
        width: 100%;
    }
    .t-login_wrap {
        box-shadow: 0 rem(10px) rem(20px) rgba(0, 0, 0, 0.16);
    }
    .t-login_logo {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: rem(180px);
            background-color: $trinity_gray;
        }
    }
    .t-login_contact {
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .t-login_form-wrap {
        position: relative;
    }
    .t-form_msg {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5rem;
        margin: 0!important;
        color: $white!important;
        width: 100%;
        &.error {
            background-color: $trinity_second;
        }
    }
}

.t-c-gray {
    color: $trinity_gray;
}

.t-link {
    text-decoration: underline;
    &:hover {
        color: $trinity_primary;
    }
}

.t-button {
    padding: 0.5rem 1.8rem;
    border: 1px solid $trinity_primary;
    border-radius: rem(45px);
    color: $trinity_primary;
    transform: $speed $easing;

    &.-white {
        border-color: $white;
        color: $white;
    }

    @include u-hocus {
        background-color: $trinity_primary;
        color: $white;

        &.-white {
            background-color: $white;
            color: $trinity_primary;
        }
    }
}

.t-form_item {
    position: relative;
    margin-bottom: rem($unit-small);
}

.t-form_label {
    position: absolute;
    display: block;
    top: 0.7rem;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s $easing;
    pointer-events: none;
    color: $trinity_gray;
    opacity: 0.8;
}

.t-form_item {
    &.is-active {
      label {
        transform: translateX(-50%) translateY(calc(-100% - 0.7rem));
      }
  
      ::placeholder {
        opacity: 0.54;
      }
    }
  }

.t-form_input {
    padding: rem(10px);
    padding-left: 1.5rem;
    border: 1px solid $trinity_gray;
    background-color: white;
    border-radius: rem(8px);
    text-align: center;

    &:hover {
        border-color: $trinity_primary;
    }

    &:focus {
        border-color: $trinity_primary;
    }

    &::placeholder {
        color: gray;
    }
}

.t-form_checkboxLabel {
    @extend .t-form_label;

    position: relative;
    display: inline-block;
    margin-right: rem(10px);
    margin-bottom: 0;
    padding-left: ($checkbox + rem(10px));
    cursor: pointer;
    pointer-events: initial;
    top: auto;
    left: auto;
    transform: none;

    &::before, &::after {
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        margin-top: (-$checkbox / 2);
        padding: 0;
        width: $checkbox;
        height: $checkbox;
        content: "";
    }

    &::before {
        background-color: $white;
        border: 1px solid lightgray;
        border-radius: 4px;
    }

    &::after {
        border-color: transparent;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2210.5%22%20viewBox%3D%220%200%2013%2010.5%22%20enable-background%3D%22new%200%200%2013%2010.5%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23#{$checkbox-icon-color}%22%20d%3D%22M4.8%205.8L2.4%203.3%200%205.7l4.8%204.8L13%202.4c0%200-2.4-2.4-2.4-2.4L4.8%205.8z%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(12px);
        background-repeat: no-repeat;
        opacity: 0;
    }

    &:hover {
        &::before {
            border-color: darkgray;
        }
    }

    .t-form_checkbox:focus + & {
        &::before {
            border-color: dimgray;
        }
    }

    .t-form_checkbox:checked + & {
        &::after {
            opacity: 1;
        }
    }
}

.t-form_checkbox {
    position: absolute;
    width: 0;
    opacity: 0;
}