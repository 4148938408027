.c-text{
    color: $grey !important;
    font-family: 'Libre';
    font-size: 14px;
    line-height: 1.375rem;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    .alignleft:not(table),
    img.alignleft {
        display: inline;
        float: left;
        margin-right: 1.5em;
    }
    .alignright:not(table),
    img.alignright {
        display: inline;
        float: right;
        margin-left: 1.5em;
    }
    .aligncenter:not(table),
    img.aligncenter {
        clear: both;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
