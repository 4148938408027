@keyframes opacity1{
  0% {
    opacity: 0;
  } 
  90% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.c-nav{
  display: flex;
  justify-content: center;

  .o-layout{
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .o-layout_item{
    width: fit-content;
  }

  .c-nav-h1{

    img{
      transition: 0.3s ease;
    }

    &:hover{
      color: $second;

      span{
        transform: translateX(10px);
      }

      img{
        transform: translateX(-10px);
      }
    }
  }

  .c-nav-h2{
    padding-top: 1rem;
    color: $grey2;
    font-size: $font-size-h6;
    font-family: 'Libre';
  }
}
.c-nav_imgDeco{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

.c-nav_menuSlide{
  // display: flex;
  position: absolute;
  top: 73px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $dark-blue;
  transition: 0.3s ease-out;
  transform: translateY(-120%);
  z-index: 4;
  overflow: auto;
  padding: 6rem 0 0 6rem;

  .c-nav_link_container{
    display: flex;
    flex-direction: column;
    // padding: 6rem 0 0 6rem;
    width: fit-content;
    transform: translateY(80%);
    transition: 0.6s ease-out;

    .c-nav_link_component{
      display: flex;
      flex-direction: column;
      // width: fit-content;
      
      .c-nav_primary_link{
        cursor: pointer;
        span{
          color: white;
          display: flex;
          position: relative;

          justify-content: space-between;
          
          .c-nav_primary_plus{
            // position: absolute;
            // top: 1rem;
            // left: 120%;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            flex-shrink: 0;

            &::before{
              content: '';
              height: 4px;
              width: 20px;
              background-color: $second;
              transition: 0.3s ease-out;
            }
            &::after{
              content: '';
              height: 20px;
              width: 4px;
              background-color: $second;
              position: absolute;
              transition: 0.3s ease-out;
            }
          }

          .rotate{
            &::after{
              transform: rotate(90deg);
            }
          }
        }
        &:hover, &.is-active {
          opacity: 0.7;
        }
      }

      .c-nav_primary_sublink {
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        // justify-content: space-between;
        height: 0;
        overflow: hidden;

        .c-nav_primary_sublink_wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        
        a{
          color: white;
          opacity: 0.5;
          font-family: 'Kanit';
          font-size: 20px;
          transition: 0.3s ease;
          // height: 0.5rem;

          &:hover{
            opacity: 1;
          }
        }
      }

      .sub-open{
        height: 100%;

        // a{
        //   height: 2rem;
        // }
      }
      &:last-of-type {
        .c-nav_primary_sublink {
          .c-nav_primary_sublink_wrap {
            padding-bottom: 2rem;
          }
        }
      }
    }
  }

  .c-nav_info{
    display: flex;
    align-items: flex-start;
    // padding: 6rem 0 0 10rem;
    width: 100%;
    justify-content: center;
    transform: translateY(80%);
    transition: transform 0.6s ease-out;

    
  }

  .c-nav_deco{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    transform: rotateY(180deg);

    svg{
      width: 100%;
      transform: translateY(7px);

      path{
        fill: #29456C;
      }
    }
  }
  @media (max-width: 999px){
    .c-nav_link_container{
      .c-nav_primary_link{
        .-h4{
          font-size: 2.2857142857rem;
        }
      }
    }
  }

  @media (max-width: 823px){
    .c-nav_info{
      flex-direction: column;
      padding: 6rem 0 0 5rem;

      .c-footer_info{
        flex-grow: inherit;
      }

      .c-footer_reseaux{
        padding: 4rem 0 0 15%;
      }
    }
  }

  @media (max-width: 500px){
    flex-direction: column;
    align-items: center;

    .c-nav_link_container{
      padding: 4rem 0 1rem;
    }

    .c-nav_info{
      padding: 0;
      width: fit-content;
      text-align: center;
      align-items: center;

      .c-footer_info{
        padding-left: inherit;
      }

      .c-footer_reseaux{
        display: none;
      }

      .c-nav_deco{
        display: none;
      }
    }
  }
}

.has-menuOpen{
  transform: translateY(0);

  .c-nav_link_container, .c-nav_info{
    transform: translateY(0%);
    animation: opacity1;
    animation-duration: 0.3s;
  }
}


