// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
    min-height: 100%; /* [2] */
    color: $color;
    font-family: $font-family;
    font-weight: $light;
    line-height: $line-height; /* [1] */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $primary;

    @media (max-width: $to-small) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        font-size: $font-size - 1px;
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        font-size: $font-size; /* [1] */
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        font-size: $font-size + 1px;
    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
        font-size: $font-size + 2px;
    }

    @media (min-width: $from-colossal) {
        font-size: $font-size + 4px;
    }

    &.is-loading {
        cursor: wait;
    }

    &.has-scroll-smooth {
        overflow: hidden;
    }

    &.has-scroll-dragging {
        user-select: none;
    }
}

body {
    .has-scroll-smooth & {
        overflow: hidden;
    }
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 325px;
  overflow: hidden;

  & > img{
      height: 325px;
      object-fit: cover;
      object-position: center;
      filter: brightness(0.7);
    }
}

.app {
  opacity: 0;
  transition: opacity 0.6s $easing;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.is-animated .app {
  opacity: 1!important;
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $link-color;
    transition: 0.3s $easing;

    @include u-hocus {
        color: $link-hover-color;
    }
}

::-webkit-scrollbar {
    width: .4em;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
}
::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid $grey;
    border-radius: 5px;
}