.b-accueil{
    .o-container{
        margin-left: inherit;
        margin-right: inherit;
        position: relative;
    }

    .o-layout_item{
        .c-heading{
            position: absolute;
            left: 6rem;
        }
    }

    .order-2{
        width: 130% !important;
    }

    .b-accueil_content{
        @media (max-width: 951px){
            .-h4{
                position: inherit;
            }

            .u-margin-top-double{
                margin-top: 4rem !important;
            }
        }
    }

    .b-accueil_img{
        .c-figure{
            position: absolute;
            left: 0;
            bottom: -7rem;
            height: 100%;
            width: 35rem;
        }

        @media (max-width: 951px){
            display: none;
        }
    }
}
