.c-heading {
    line-height: $line-height-h;
    margin-bottom: rem(18px);
    font-family: 'Kanit';

    &.-noMargin {
      margin-bottom: 0;
    }

    &.-h1 {
        font-size: rem($font-size-h1);
        font-weight: $bold;
        line-height: $line-height-default;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h1)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h1)} - 20px);
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        font-weight: $bold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h2)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h2)} - 15px);
        }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        font-weight: $bold;
        color: $primary;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h3)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h3)} - 15px);
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        font-weight: 500;
        color: $primary;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h4)} - 10px);
        }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h5)} - 7px);
        }
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        font-weight: $medium;

        &.-upper{
          text-transform: uppercase;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
