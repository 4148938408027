.b-fichiers {
    .b-fichiers_wrapper{
        display: flex;
        flex-direction: column;
        @media(max-width: 700px){
            flex-direction: column;
        }
    }

    @media (max-width: $from-small){
        .b-fichiers_component{
            // flex-direction: column;
            // margin: 0 auto 2rem;
            // width: fit-content !important;
            // gap: 0.5rem;

            &:not(:last-of-type){
                margin-bottom: 2rem;
            }
        }
    }
}

.b-fichiers_component{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $black;

    .b-fichiers_icon{
        height: rem(42px);
        width: rem(42px);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: $second;

        svg{
            z-index: 2;
        }

        &::after{
            content: "";
            position: absolute;
            left: 2px;
            top: 2px;
            right: 2px;
            bottom: 2px;
            background-color: white;
            border-radius: 50%;
            transition: $speed $easing;
        }
    }

    .b-fichiers_extension{
        
    }

    &:hover{
        .b-fichiers_icon{
            &::after{
                transform: scale(0);
            }
        }
    }
}
