.b-equipe{
    position: relative;
    padding-top: inherit;

    .owl-next, .owl-prev{
        width: 3rem;
        height: 3rem;
        border-bottom: 3px solid $second !important;
        display: flex;
        justify-content: center;
        position: absolute;
        top: calc(50% - 3rem);
        cursor: pointer;
        overflow: hidden;
        border-radius: inherit !important;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 3rem;
            height: 3rem;
            background-color: $second;
            transition: all .3s ease-out;
            border-radius: 10rem 10rem 0 0;
            transform: translateY(100%);
        }
        svg{
            position: relative;
            z-index: 2;
            transition: 0.3s ease-out;
        }
        &:hover {
            background: inherit !important;
            &:before {
                height: 100%;
                border-radius: inherit;
                transform: translateY(0);
            }
            svg{
                z-index: 1;
                path{
                    fill: white;
                }
            }
        }

        @media (max-width: 700px){
            top: -5rem;
        }
    }

    .owl-prev{
        left: -5rem;

        @media (max-width: 700px){
            left: 1rem;
        }

        svg{
            transform: rotate(180deg);
        }
    }

    .owl-next{
        right: -5rem;

        @media (max-width: 700px){
            right: 1rem;
        }
    }

    header{
        height: 60%;
        background-color: $sky-blue;
        position: absolute;
        width: 100%;
        left: 0;
        padding-top: 4rem;

        svg{
            padding: 1rem;
            height: fit-content;
            width: fit-content;
        }
    }

    .b-equipe_cards_component{
        transition: 0.5s ease;

        @media (max-width: 520px){
            &:not(:first-of-type){
                padding-left: 2rem !important;
            }
        }
    }

    .b-equipe_image{
        width: 15rem;
        height: 15rem;

        @media (max-width: 520px){
            width: 12rem;
            height: 12rem;
        }
    }

    .b-equipe_cards_container{
        padding-top: 11rem;
    }

    .-h3{
        text-transform: uppercase;
        color: $primary;
        margin: auto;
        width: fit-content;
    }

    .-h5{
        margin-bottom: inherit;
        font-size: 20px;
        text-transform: uppercase;
        color: $primary;
    }

    .b-equipe_card_content{
        padding-top: 1rem;

        span{
            color: $second;
            font-size: $font-size;
            font-weight: 400;
        }

        @media (max-width: 520px){
            width: 12rem !important;
        }
    }
}
.owl-dots{
    display: none;
}
