.b-paragraphe{
    display: flex;
    flex-direction: column;

    .b-paragraphe_title{
        color: $primary;
        padding-bottom: 1rem;
    }

    .b-paragraphe_text{
        color: $primary;
        float: right;
        width: 85%;
        padding-right: 1rem;

        &:first-of-type{
            padding-right: inherit;
        }
    }

    .-column2{
        width: 42.5% !important;
    }

    .-column3{
        width: 28.333% !important;
    }

    @media (max-width: 750px){
        .o-container{
            .o-layout{
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .b-paragraphe_title{
                    width: 100%;
                }

                .b-paragraphe_text{
                    width: 85% !important;
                }
            }
        }
    }
}
