.b-media-texte{
    .o-layout{
        display: flex;
        align-items: center;

        .o-layout_item{
            // .c-heading{
            //     margin-bottom: 3.8rem;
            //     font-size: 48px;

            //     @media (max-width: 951px){
            //         left: 0;
            //     }
            // }
        }

        .b-media-texte_img{
            img{
                min-height: 450px;
                object-fit: cover;
            }
        }
    }

    @media (max-width: 950px){
        .o-layout{
            flex-direction: column;

            .o-layout_item{
                width: 100% !important;
            }

            .b-media-texte_img{
                margin-top: 3rem;
                order: 2 !important;
                display: flex;
                justify-content: center;
            }
        }
    }
}
