.c-card {
  position: relative;
  &.-page {
    @media (max-width: $to-small) {
      transform: none!important;
    }
    .c-card_image {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      .c-figure {
        width: 100%;
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      img {
        transition: $speed $easing;
      }
    }
    .c-card_wrap {
      
    }
    &:hover {
      .c-card_image {
        overflow: hidden;
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  &.-actualite {
    max-width: 575px;
    transition: $speed $easing;
    height: 100%;

    .c-card_picto {
      transition: $speed $easing;
    }

    &:hover{
      background-color: $grey-day;
      div, span{
        color: $second !important;
      }
      .c-figure {
        overflow: hidden;
        img {
          transform: scale(1.05);
        }
      }
      
      svg{
        path{
          fill: $second;
        }
      }
      .c-card_picto {
        transform: translateX(-1rem);
      }
    }

    .c-card_wrap{
      display: flex;
      align-items: center;
      border-bottom: 3px solid $primary;
      height: 100%;

      .c-card_image{
        .c-figure{
          max-width: 230px;

          img{
            transition: 0.3s ease-out;
          }
        }
      }

      .c-card_content{
        padding: 1rem 0 0 1rem;
        flex: 1;
        .c-card_body{
          .-h3{
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 2rem;
          }
          
          .-h6{
            font-size: 14px;
            font-family: 'Kanit';
            opacity: 0.5;
          }
        }

        .c-card_foot{
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;

          span{
            font-family: 'Libre';
            line-height: 1rem;
            svg{
              width: 1rem;
            }
          }
        }
      }
    }
  }

  &.-evenement {
    overflow: hidden;
    padding-top: rem($unit-small);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0; 
      height: 3px;
      width: 100%;
      background-color: $primary;
      z-index: 2;
    }

    .c-card_wrap{
      @media (max-width: 500px){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 210px;
        border-bottom: 3px solid $primary;

        .c-card_day{
          margin: 0 auto;
          transform: translate(-1.4rem, 3rem);
          font-size: 10rem;
        }

        .c-card_content{
          padding: 0;
        }
      }
    }

    .c-card_day {
      font-size: rem(208px);
      font-weight: 500;
      color: $grey-day;
      //color: #EDF0F8;
      line-height: 0;
      transform: translate(-1.4rem, 0.2rem);
    }
    &:hover {
      background-color: $grey-day;
      .c-card_day {
        opacity: 0.15;
      }
    }
  }

  &.-lien {
    .c-card_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 15rem;
      border-top: 2px solid $black;
      margin-top: rem($unit-small);
    }
    .c-card_picto {
      transition: $speed $easing;
      svg {
        width: rem(20px);
      }
    }

    &:hover {
      .c-card_picto {
        transform: translateX(-1rem);
      }
    }
  }
}
