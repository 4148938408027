.c-dropdown {
    position: relative;
    z-index: 5;
    font-family: $font-kanit;
    .c-dropdown_selected {
        background-color: $second;
        color: $primary;
        cursor: pointer;
        .c-button_icon {
            // transform: rotate(90deg);
            transition: $speed $easing;
        }
    }
    .c-dropdown_content {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        transition: $speed $easing;
        transform: translateY(rem(-$unit_tiny));
        opacity: 0;
    }

    .c-dropdown_content_wrap {
        background-color: $second;
        overflow: auto;
        min-width: 100%;
        width: rem(250px);
        text-align: left;
        max-height: rem(350px);
        padding: 0 rem($unit-small);
    }

    .c-dropdown_link {
        border-bottom: 2px solid $primary;
        padding: rem($unit-tiny) 0;
        display: block;
        color: $primary;
        &:last-of-type {
            border-bottom: none;
        }
        &:hover {
            color: $white;
        }
    }

    &.is-open {
        .c-dropdown_selected {
            background-color: rgba($second, 0.85);
        }
        .c-dropdown_content {
            visibility: visible;
            transform: translateY(0);
            opacity: 1;
        }
        .c-dropdown_selected {
            .c-button_icon {
                transform: rotate(-180deg);
            }
        }
    }
}