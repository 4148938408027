// .b-relation{
//     .b-relation_listing{
//         display: flex;
//         flex-direction: column;
//     }
// }

.b-relation_style_colonne{
    height: 755px;
    transform: translate(50%, -100%);
}
