.c-page-header{
    padding-top: 6rem;
    max-width: inherit;
    h1, p{
        color: white;
        width: 40%;
        z-index: 2;

        @media (max-width: 906px){
            width: 100%;
            min-width: inherit;
        }
    }

    p{
        font-family: 'Libre';
    }

    h1{
        font-size: $font-size-h1;
        font-weight: bold;
        width: max-content;
        padding-bottom: 2rem;
    }

    nav{
        padding-top: 6rem;

        .c-page-header_navItems{
            padding: 1rem 0;
            margin: 2rem 3rem 0 0;
            border-bottom: 2px solid $second;

            a{
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: inherit;

                span{
                    transition: transform 0.3s ease;
                }

                img{
                    padding-left: 2rem;
                    transition: 0.3s ease;
                }
            }

            &:hover{
                a{
                    span{
                        transform: translateX(10px);
                    }

                    img{
                        transform: translateX(-10px);
                    }
                }
            }
        }
    }

    .c-page-header_deco{
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        height: 100%;
        z-index: -1;

        svg{
            path{
                fill: $dark-blue;
            }
        }

        @media (max-width: 906px){
            display: none;
        }
    }

    @media (max-width: 900px){
        width: 100%;

        h1{
            width: 90%;
        }
    }

    @media (max-width: 457px){
        padding-top: 0;
        
        h1{ 
            font-size: 42px;
        }

        nav{
            padding-top: 3rem;
        }
    }
}

.c-page-header-img{
    max-height: 390px;
    overflow: hidden;
    display: flex;
    align-items: center;

    img{
        width: 100%;
    }
}

.c-page-header_subtitle{
    color: $grey2;
    padding-top: 0.5rem;
    font-family: 'Libre';
    align-self: flex-start;
}

.c-page-header_link_component{
    width: 100%;
    margin-bottom: 1rem;
    @media (max-width: $from-small){
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    a{
        width: 100%;

        .c-button_label{
            width: 100%;
        }
    }
}

.c-page-header_container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 1700px){
        display: flex;

        .c-page-header_component{
            margin-top: 2rem;
            width: 33%;
        }
    }

    @media (max-width: 1300px){
        .c-page-header_component{
            width: 50%;
            margin-top: 3rem;

            p{
                margin-bottom: 1rem;
            }
        }
    }

    @media (max-width: 850px){
        .c-page-header_component{
            width: 70%;
            padding-right: inherit !important;
        }
    }

    @media (max-width: 850px){
        .c-page-header_component{
            width: 85%;
        }
    }
}

.c-page-header_component{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    p{
        border-bottom: 1px solid $grey;
        padding-bottom: 1rem;
        margin-bottom: 3rem;
        width: 100% !important;
    }

    &:not(:last-of-type){
        padding-right: 5rem;
    }
}
