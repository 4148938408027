.c-flap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  transition: transform $speed $easing;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 2rem;
  pointer-events: none;
  .c-flap_bg {
    pointer-events: auto;
    &::before {
      visibility: hidden;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
    }
  }
  .c-flap_nav {
    display: flex;
    flex-direction: column;
  }
  .c-flap_button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: rem($unit-small);
    position: relative;
    z-index: 2;
    pointer-events: auto;
    .c-flap_button-label {
      color: $white;
      margin-right: rem($unit-small);
      letter-spacing: 0.9px;
      text-transform: uppercase;
      transition: $speed $easing;
    }
    .c-flap_icon {
      width: rem(80px);
      height: rem(80px);
      border-radius: 50%;
      transition: $speed $easing;
      transform-origin: center center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      svg {
        max-width: rem(50px);
        max-height: rem(50px);
      }
      &.c-flap_icon-close {
        svg {
          max-width: rem(80px);
          max-height: rem(80px);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(80px);
        height: rem(80px);
        transition: $speed $easing;
        transform-origin: center center;
        background-color: $white;
        border-radius: 50%;
        z-index: -1;
      }
    }
    &.-primary {
      .c-flap_icon {
        color: $white;
        &::after {
          background-color: $primary;
        }
      }
    }
    &:hover {
      .c-flap_button-label {
        color: $second;
      }
      .c-flap_icon {
        color: $second;
        &::after {
          width: rem(100px);
          height: rem(100px);
          background-color: $grey-light;
        }
      }
      &.-primary {
        .c-flap_icon {
          color: $white;
          &::after {
            background-color: $second;
          }
        }
      }
    }
    &.-hide {
      visibility: hidden;
      opacity: 0;
      transform: translateY(calc(100% + #{rem($unit-small)}));
      transition: transform $speed $easing, opacity $speed $easing, visibility 0s;
      z-index: 1;
    }
  }
  .c-flap_button {
    .c-flap_icon-close {
      display: none;
    }
  }
}

.has-flapOpen {
  .c-flap {
    .c-flap_bg {
      &::before {
        visibility: visible;
      }
    }
    .c-flap_button {
      .c-flap_icon-open {
        display: none;
      }
      .c-flap_icon-close {
        display: block;
      }
      &.-hide {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
