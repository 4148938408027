.c-header {
  display: flex;
  justify-content: center;

  @media (max-width: 450px){
    a{
      display: flex;
      justify-content: center;

      img{
        width: 90%;
      }
    }
  }
}

.c-header-2{
  height: 73px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $medium-blue;
  padding: 0 6rem;
  position: relative;
  z-index: 5;
  background-color: $primary;

  & > a {
    position: relative;
    z-index: 2;
  }

  img{
    width: 13.5rem;
  }

  .c-header_menu{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    position: relative;
    z-index: 2;

    .c-header_nav{
      color: white;
      padding: 0 1.5rem;

      button{

        .c-header_nav-icon{
          order: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          span{
            background-color: white;
            width: 23px;
            height: 2px;
            margin: 3px 0;
            transition: transform 0.3s ease-out;
          }
        }

        .c-button_label{
          padding-right: 1rem;
          min-width: inherit;

          @media (max-width: $from-tiny){
            display: none;
          }
        }
      }
    }
    .c-header_school{
      @media (max-width: 381px){
      .c-button_label{
        padding-right: 1rem;
        span{
            display: none;
          }
        }
      }
    }

    .c-header_school{
      .icon{
        width: 1.5rem;
        margin-left: 1rem;
      }
    }

    @media (max-width: 350px){
      margin-left: 0.5rem;
    }
  }

  @media (max-width: 593px){
    padding: 0 0 0 0.5rem;

    .c-header_menu{
      .c-header_nav{
        padding: 0 2.5rem 0 0.5rem;
      }

      .c-header_school{
        padding: 0 0.5rem;
      }
    }

    details{
      margin-left: 1rem;
      margin-right: 0.5rem;
    }
  }

  @media (max-width: $from-small){
    details{
      display: none;
    }

    .c-header_menu{
      .c-header_nav{
        padding: 0 0.5rem 0 0.5rem;
      }
    }
  }
}

.c-header_uppernav{
  height: 100%;
  background-color: $second;
  padding: 0 2rem;
  margin-left: 3rem;
  margin-bottom: inherit;
  display: flex;
  align-items: center;

  .c-heading{
    margin-bottom: inherit;
  }

  .c-header_uppernav_icon{
    width: 1rem;
    margin-left: 1rem;
  }

  &[open]{
    background-color: rgba(220, 115, 68, 0.85);
  }
}

details {
  position: relative;
  margin-right: 1rem;
  z-index: 2;

  details[open] {
    z-index: 1;
  }

  summary {
    cursor: pointer;
    border-radius: 5px;
    color: $primary;
    list-style: none;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  summary:focus {
    outline: none;
  }

  details[open] summary:after {
    transform: rotate(-45deg) translate(0%, 0%);
  }

  ul {
    width: 100%;
    background: $second;
    color: $primary;
    font-weight: bold;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 1rem 2rem;
    margin: 0;
    box-sizing: border-box;
    overflow-y: auto;
  }

  li {
    margin: 0;
    padding: 1rem 0;
    border-bottom: 2px solid $primary;
    text-transform: uppercase;
  }

  li:first-child {
    padding-top: 0;
  }

  li:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  /* FAKE SELECT */

  summary.radios {
    counter-reset: radios;
  }

  input[type=radio] {
    counter-increment: radios;
    appearance: none;
    display: none;
    width: fit-content;
    text-transform: uppercase;
  }

  input[type=radio]:checked {
    display: inline;
  }

  input[type=radio]:after {
    content: attr(title);
    display: inline;
    font-size: 1rem;
  }

  ul.list {
    counter-reset: labels;
  }

  label {
    width: 100%;
    display: block;
    cursor: pointer;

    a{
      color: $primary;

      &:hover{
        color: $grey-light;
      }
    }
  }
}
