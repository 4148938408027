.c-accordion_item {
  background-color: $sky-blue;
  margin-bottom: 1rem;

  .c-accordion_header {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem;
    transition: color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    .c-accordion_title{
      font-size: $font-larger;
      text-transform: uppercase;
      color: $primary;
      font-family: 'Kanit';
      font-weight: 500;
      width: 100%;
      overflow: hidden;
      height: 2.5rem;

      p{
        display: flex;
        flex-direction: column;
        transition: transform 0.3s ease-out;
        height: fit-content;

        &::after{
          content: attr(data-text);
          position: relative;
          color: $second;
        }
      }
    }

    &:hover{
      .c-accordion_title{
        p{
          transform: translateY(-50%);
        }
      }

      .c-accordion_plus{
        &::before{
          border-top: 3px solid $second;
        }

        &::after{
          border-left: 3px solid $second;
        }
      }
    }
  }
  .c-accordion_plus {
    position: relative;
    width: 1rem;
    height: 1rem;
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    flex-shrink: 0;
    margin-left: 0.66667rem;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      transition: border-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::before {
      border-top: 3px solid $black;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -1.5px;
    }
    &::after {
      border-left: 3px solid $black;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -1.5px;
    }
    .icon {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      svg {
        max-width: 50px;
      }
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.is-open {
    .c-accordion_header{
      .c-accordion_title{
        padding-bottom: 1rem;
        border-bottom: 2px solid $second;
        color: $second;
      }
    }
    .c-accordion_main {
      height: auto;
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
    }
    .c-accordion_plus::after {
      transform: scale(0);
    }
    .c-accordion_plus::before{
      border-top: 3px solid $second;
    }
  }
}
