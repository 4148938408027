.c-button {
  display: inline-flex;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-family: 'Libre';
  font-weight: 500;
  text-transform: uppercase;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: $medium-blue;
    transition: all .3s;
    z-index: -1;
    border-radius: 10rem 10rem 0 0;
    width: 3rem;
    height: 3rem;
    transform: translateY(100%);
  }

  &.-second{
    .c-button_label{
      min-width: 12rem;
      color: $second;
    }
  }

  .c-button_label{
    color: white;
    min-width: 17rem;
    border-bottom: 3px solid $second;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size;

    span{
      padding-right: 3rem;
      transition: 0.3s ease-out;
    }

    svg{
      transition: 0.3s ease-out;

      path{
        transition: 0.3s ease-out;
      }
    }
  }
  
  &.-third{
    height: 100%;
    margin-bottom: inherit;
    align-items: center;
    transition: inherit;
    padding: 0 1.5rem;

    .c-button_label{
      border: inherit;
      color: white;
      min-width: inherit;

      span{
        padding-right: 0;
      }
    }
  }

  &.-four{
    margin-right: 3rem;
    .c-button_label{
      min-width: inherit;
    }

    @media (max-width: 745px){
      padding-top: 1rem;
    }
  }

  &:hover {
    button, a{
      color: $second !important;
    }

    .c-button_label{
      color: $second;
      span{
        transform: translateX(1.2rem);
      }

      svg, img{
        transform: translateX(-1.2rem);
      }
    }

    &::before{
      transform: translateY(0);
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    &.-second {
      .c-button_label{
        color: $white;
      }
      path {
        fill: $white;
      }
      &::before {
        background-color: $second;
      }
    }

    &.-third{
      color: $second;
      .c-button_label{
        color: $second;
        span, img{
          transform: inherit;
        }
      }
    }
  }
}

.c-button-full {
  padding: rem(15px) rem(20px);
  background-color: $primary;
  color: $white;
  transition: 0.3s $easing;
  font-weight: $medium;
  display: flex;
  align-items: center;
  .icon {
    margin-right: 1rem;
  }
  @include u-hocus {
    background-color: $second;
  }
  &.-addtocart {
    border-radius: 0px 10px 10px 0px;
  }
}

.c-button-round {
  display: grid;
  overflow: hidden;
  border-radius: 50%;
  max-width: 500px;
  .c-button-round_round {
    grid-column: 1;
    grid-row: 1;
    padding-bottom: 100%;
    border-radius: 50%;
    background-color: $grey-light;
    transform-origin: center center;
    transform: scale(0.8);
    transition: 0.3s $easing;
  }
  .c-button-round_btn {
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s $easing;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  &:hover {
    .c-button-round_round {
      transform: scale(1);
      background-color: $grey;
    }
    .u-c-primary {
      color: $second;
    }
  }
}
